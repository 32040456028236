import React from "react";
import ContentWrapper from "components/content-wrapper";
import TicketShop from "components/ticket-shop";
import SEO from "components/seo";

const BirminghamPage = () => {
  return (
    <ContentWrapper>
      <SEO title="Freshers Takeover Birmingham"></SEO>
      <div>
        <h1>BIRMINGHAM FRESHERS EVENTS</h1>
        <div style={{ position: "relative", width: "680px", maxWidth: "100%" }}>
          <TicketShop
            scriptString={`<script
          src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js"
          data-fixr-shop-id="404f9c77-df1e-4aec-9937-4b010b9125c3"
          data-fixr-theme="dark"
        ></script>`}
          ></TicketShop>
        </div>
      </div>
    </ContentWrapper>
  );
};

export default BirminghamPage;
